import axios from "axios";
import vm from "@/main";

export default class LoginService {
  static apiUrl = process.env.VUE_APP_API_BASE_URL || "https://mwaa-v8-api.pointr.cloud/api/v8";
  static async getToken(input) {
    try {
      if (vm) {
        vm.$store.commit("ADD_BACKGROUND_LOADING_REQUEST");
      }
      const response = await axios.post(this.apiUrl + "/auth/token", input);
      return response?.data;
    } catch (error) {
      throw error?.response?.data;
    } finally {
      if (vm) {
        vm.$store.commit("REMOVE_BACKGROUND_LOADING_REQUEST");
      }
    }
  }

  static async resetPassword(input) {
    try {
      vm.$store.commit("ADD_LOADING_REQUEST");
      const response = await axios.post(this.apiUrl + "/auth/resetPassword", input);
      return response?.data;
    } catch (error) {
      throw error?.response?.data;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
  }

  static async forgotPassword(input) {
    try {
      vm.$store.commit("ADD_LOADING_REQUEST");
      const response = await axios.post(this.apiUrl + "/auth/password", input);
      return response?.data;
    } catch (error) {
      throw error?.response?.data;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
  }

  static async logout(input) {
    try {
      await axios.post(this.apiUrl + "/auth/logout", input);
    } catch (error) {
      console.warn("Error logging out");
    }
  }
}
